var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.infoList)?_c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter3"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数03_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context"},[_c('div',{staticClass:"up"},[_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-PZ1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.FLPSPJB,
              'C05-PZ1',
              'FLPSPJB',
              _vm.infoList.FLPSPJB_node_string
            )}}},[_vm._v(" "+_vm._s("FLPSPJB" in _vm.infoList ? _vm.infoList.FLPSPJB : 0)+" ")])])]),_c('div',{staticClass:"middle flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"shadow shadow2 float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-JBSP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWSP_B1,
                    'C01-JBSP',
                    'CWSP_B1',
                    _vm.infoList.CWSP_B1_node_string
                  )}}},[_vm._v(" "+_vm._s("CWSP_B1" in _vm.infoList ? _vm.infoList.CWSP_B1 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-CWK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KCWSPBC,
                      'C01-CWK',
                      'KCWSPBC',
                      _vm.infoList.KCWSPBC_node_string
                    )}}},[_vm._v(" "+_vm._s("KCWSPBC" in _vm.infoList ? _vm.infoList.KCWSPBC : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-SJSP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'CWSP_SJ',
                      _vm.infoList.CWSP_SJ_node_string,
                      'CWSP_SJVV1',
                      '床温实际控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.CWSP_SJ,
                      'CWSP_SJ',
                      _vm.infoList.CWSP_SJ_node_string,
                      'CWSP_SJVV1'
                    )}}},[_vm._v(" "+_vm._s("CWSP_SJ" in _vm.infoList ? _vm.infoList.CWSP_SJ : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow2 float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("F04-CWSPH")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ECF_CWMAXH,
                    'F04-CWSPH',
                    'ECF_CWMAXH',
                    _vm.infoList.ECF_CWMAXH_node_string
                  )}}},[_vm._v(" "+_vm._s("ECF_CWMAXH" in _vm.infoList ? _vm.infoList.ECF_CWMAXH : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("F04-CWSPL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ECF_CWMAXL,
                      'F04-CWSPL',
                      'ECF_CWMAXL',
                      _vm.infoList.ECF_CWMAXL_node_string
                    )}}},[_vm._v(" "+_vm._s("ECF_CWMAXL" in _vm.infoList ? _vm.infoList.ECF_CWMAXL : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("F04-CWSP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SP',
                      _vm.infoList.RSF0E_node_string,
                      'RSF0E_SPVV1',
                      'RSF0E设定值',
                      'RSF0E'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.RSF0E.SP,
                      'SP',
                      _vm.infoList.RSF0E_node_string,
                      'RSF0E_SPVV1'
                    )}}},[_vm._v(" "+_vm._s("RSF0E" in _vm.infoList ? _vm.infoList.RSF0E.SP : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow1 flex float3"},[_c('div',{staticClass:"column1"},[_vm._v("C02-CWJZ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CWJZ',
                  _vm.infoList.CWJZ_node_string,
                  'CWJZVV1',
                  '床温均值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.CWJZ,
                  'CWJZ',
                  _vm.infoList.CWJZ_node_string,
                  'CWJZVV1'
                )}}},[_vm._v(" "+_vm._s("CWJZ" in _vm.infoList ? _vm.infoList.CWJZ : 0)+" ")])])])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"button float1"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.FLYCC,
                '按钮',
                'FLYCC',
                _vm.infoList.FLYCC_node_string
              )}}},[_vm._v(" "+_vm._s("FLYCC" in _vm.infoList ? _vm.infoList.FLYCC ? "区间控制" : "点控制" : 0)+" ")])]),_c('div',{staticClass:"buttonx float2",style:({
            background:
              _vm.infoList.XK09 && !_vm.infoList.XK09.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK09
              ? _vm.toDetail(
                  1,
                  'XK09',
                  _vm.infoList.XK09_node_string,
                  '',
                  '床温返料调节'
                )
              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"shadow shadow3 float3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-CWZ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CWJZ_Z',
                  _vm.infoList.CWJZ_Z_node_string,
                  'CWJZ_ZVV1',
                  '左侧床温均值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.CWJZ_Z,
                  'CWJZ_Z',
                  _vm.infoList.CWJZ_Z_node_string,
                  'CWJZ_ZVV1'
                )}}},[_vm._v(" "+_vm._s("CWJZ_Z" in _vm.infoList ? _vm.infoList.CWJZ_Z : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-CWY")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CWJZ_Y',
                  _vm.infoList.CWJZ_Y_node_string,
                  'CWJZ_YVV1',
                  '右侧床温均值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.CWJZ_Y,
                  'CWJZ_Y',
                  _vm.infoList.CWJZ_Y_node_string,
                  'CWJZ_YVV1'
                )}}},[_vm._v(" "+_vm._s("CWJZ_Y" in _vm.infoList ? _vm.infoList.CWJZ_Y : 0)+" ")])])]),_c('div',{staticClass:"buttonr float4",style:({
            background:
              _vm.infoList.RSF01 && !_vm.infoList.RSF01.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RSF01
              ? _vm.toDetail(
                  3,
                  'RSF01',
                  _vm.infoList.RSF01_node_string,
                  '',
                  '返料平衡软伺服'
                )
              : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"box float5"},[_c('div',{staticClass:"column3"},[_vm._v("0.5")]),_c('div',{staticClass:"button button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.HLHWTY,
                '按钮',
                'HLHWTY',
                _vm.infoList.HLHWTY_node_string
              )}}},[_vm._v(" "+_vm._s("HLHWTY" in _vm.infoList ? (_vm.infoList.HLHWTY ? "投用" : "切除") : 0)+" ")]),_c('div',{staticClass:"column3"},[_vm._v("-0.5")])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-A0")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_FL1_B',
                _vm.infoList.KF_FL1_B_node_string,
                'KF_FL1_BVV1',
                '1#返料调节反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_FL1_B,
                'KF_FL1_B',
                _vm.infoList.KF_FL1_B_node_string,
                'KF_FL1_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_FL1_B" in _vm.infoList ? _vm.infoList.KF_FL1_B : 0)+" ")])]),_c('div',{staticClass:"buttonx float2",style:({
            background:
              'MAN11' || 'MAN12' in _vm.infoList
                ? _vm.infoList.MAN11.RM == 1 || _vm.infoList.MAN12.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANFL', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"shadow shadow1 float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-A0")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_FL2_B',
                _vm.infoList.KF_FL2_B_node_string,
                'KF_FL2_BVV1',
                '2#返料调节反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_FL2_B,
                'KF_FL2_B',
                _vm.infoList.KF_FL2_B_node_string,
                'KF_FL2_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_FL2_B" in _vm.infoList ? _vm.infoList.KF_FL2_B : 0)+" ")])])])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-PZ2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.FLPSPJB2,
              'C05-PZ2',
              'FLPSPJB2',
              _vm.infoList.FLPSPJB2_node_string
            )}}},[_vm._v(" "+_vm._s("FLPSPJB2" in _vm.infoList ? _vm.infoList.FLPSPJB2 : 0)+" ")])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("返料优化控制模型")])])}]

export { render, staticRenderFns }